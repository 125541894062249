import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { translateResolution } from 'common/helper';
import { useSelector } from 'react-redux';
import withConfirm from 'material-ui-confirm';

import { RoomInfo } from '../RoomDetail';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  title: {
    flex: '0 0 auto'
  },
  table: {
    minWidth: 650
  },
  delete: {
    color: 'red'
  }
}));

function RoomList({ instant, hideTitle, confirm, ...restProps }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [open, openDialog] = useState(false);
  const [roomInfo, setRoomInfo] = useState({});
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    total: 0
  });
  const uid = useSelector(state => state.user.id || 0);
  const roomWebsite = useSelector(state => state.config.roomWebsite);
  const roomUrl = `${roomWebsite}/${roomInfo.roomName}`;
  const moderatorUrl = `${roomUrl}?jwt=${roomInfo.token}`;

  useEffect(() => {
    (async function() {
      const { data, total } = await axios.post('/api/conference/list', {
        instant,
        withToken: true,
        page: pagination.page,
        perPage: pagination.perPage
      });

      setPagination(p => ({ ...p, total }));
      setRows(data || []);
    })();
  }, [instant, pagination.page, pagination.perPage, pagination.total]);

  const showJoinInfo = row => async () => {
    openDialog(true);
    setRoomInfo(row);
  };

  const handleChangeRowsPerPage = async event => {
    setPagination({ ...pagination, perPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handleClose = () => {
    openDialog(false);
  };

  const handleDelete = roomName => async () => {
    await axios.post('/api/conference/delete', { roomName });

    setPagination({ ...pagination, total: pagination.total - 1 });
  };

  return (
    <div
      className={classes.root}
      {...restProps}
    >
      <Paper className={classes.paper}>
        {!hideTitle && (
          <Toolbar>
            <div className={classes.title}>
              <Button
                color="primary"
                component={RouterLink}
                to="/room-new"
                variant="outlined"
              >
                创建会议室
              </Button>
            </div>
          </Toolbar>
        )}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {instant && <TableCell>时间</TableCell>}
              <TableCell>会议室名称</TableCell>
              <TableCell>主题</TableCell>
              <TableCell align="center">清晰度</TableCell>
              <TableCell align="center">人数限制</TableCell>
              <TableCell align="center">参会链接</TableCell>
              <TableCell align="center">操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.roomName}>
                {instant && <TableCell>{row.startTime}</TableCell>}
                <TableCell
                  component="th"
                  scope="row"
                >
                  <Typography
                    color="primary"
                    component={RouterLink}
                    to={`/room/${row.roomName}`}
                  >
                    {row.roomName}
                  </Typography>
                </TableCell>
                <TableCell>{row.subject}</TableCell>
                <TableCell align="center">{translateResolution(row.resolution)}</TableCell>
                <TableCell align="center">{row.participantLimits || '跟随套餐'}</TableCell>
                <TableCell align="center">
                  <Button
                    color="secondary"
                    onClick={showJoinInfo(row)}
                  >
                    查看
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Button
                    color="primary"
                    href={`${roomWebsite}/${row.roomName}?jwt=${row.token}`}
                    target="_blank"
                    variant={hideTitle ? 'outlined' : 'text'}
                  >
                    开始会议
                  </Button>
                  {!hideTitle && (
                    <>
                      <Button
                        color="primary"
                        component={RouterLink}
                        to={`/room-edit/${row.roomName}`}
                      >
                        修改
                      </Button>
                      <Button
                        className={classes.delete}
                        disabled={uid.toString() === row.roomName}
                        onClick={confirm(handleDelete(row.roomName), {
                          title: '提示',
                          description: '确认要删除会议室吗？',
                          confirmationText: '删除',
                          cancellationText: '取消'
                        })}
                      >
                        删除
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          backIconButtonProps={{
            'aria-label': '上一页'
          }}
          component="div"
          count={pagination.total}
          labelRowsPerPage="每页"
          nextIconButtonProps={{
            'aria-label': '下一页'
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.perPage}
          rowsPerPageOptions={[10, 20]}
        />
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          fullWidth
          maxWidth="md"
          onClose={handleClose}
          open={open}
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="h4">会议室详情</Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <RoomInfo roomInfo={roomInfo} />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={handleClose}
            >
              关闭
            </Button>
            <Button
              autoFocus
              color="primary"
              href={moderatorUrl}
              target="_blank"
              variant="outlined"
            >
              开始会议
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </div>
  );
}

RoomList.propTypes = {
  confirm: PropTypes.func,
  hideTitle: PropTypes.bool,
  instant: PropTypes.bool
};

export default withConfirm(RoomList);
