import React, { useEffect, useState } from 'react';
import { Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { translateResolution } from 'common/helper';
import moment from 'moment';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    }
  },
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  title: {
    flex: '0 0 auto'
  },
  table: {
    minWidth: 650
  }
}));

function formatDuation(row) {
  const endTime = row.etime ? moment(row.etime) : moment();

  return moment.utc(moment(endTime).diff(row.ctime)).format('HH:mm:ss');
}

export default function ConferenceHistory({ roomName }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    total: 0
  });

  useEffect(() => {
    (async function() {
      const { data, total } = await axios.post('/api/conference/history', {
        roomName,
        page: pagination.page,
        perPage: pagination.perPage,
        includeRunning: true
      });

      setPagination(p => ({ ...p, total }));
      setRows(data || []);
    })();
  }, [roomName, pagination.page, pagination.perPage]);

  const handleChangeRowsPerPage = async event => {
    setPagination({ ...pagination, perPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>会议室名称</TableCell>
              <TableCell align="center">清晰度</TableCell>
              <TableCell align="center">最高参会人数</TableCell>
              <TableCell align="center">会议时长</TableCell>
              <TableCell align="center">开始时间</TableCell>
              <TableCell align="center">结束时间</TableCell>
              <TableCell align="center">SDK时间消耗（分钟）</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell
                  component="th"
                  scope="row"
                >
                  {row.roomName}
                </TableCell>
                <TableCell align="center">{translateResolution(row.resolution)}</TableCell>
                <TableCell align="center">{row.maxParticipants}</TableCell>
                <TableCell align="center">{formatDuation(row)}</TableCell>
                <TableCell align="center">{moment(row.ctime).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                <TableCell align="center">
                  {row.etime ? moment(row.etime).format('YYYY-MM-DD HH:mm:ss') : '正在进行中'}
                </TableCell>
                <TableCell align="center">{(row.apiDuration || 0) / 60}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          backIconButtonProps={{
            'aria-label': '上一页'
          }}
          component="div"
          count={pagination.total}
          labelRowsPerPage="每页"
          nextIconButtonProps={{
            'aria-label': '下一页'
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.perPage}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </Paper>
    </div>
  );
}

ConferenceHistory.propTypes = {
  roomName: PropTypes.string
};
