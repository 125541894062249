import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  RoomDetail as RoomDetailView,
  RoomEdit as RoomEditView,
  RoomListWrapper as RoomListView,
  ConferenceHistory as ConferenceHistoryView,
  RecordingList as RecordingListView,
  TradeCenter as TradeCenterView,
  TradeConfirm as TradeConfirmView,
  Account as AccountView,
  SignUp as SignUpView,
  SignIn as SignInView,
  Reset as ResetView,
  NotFound as NotFoundView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={RoomListView}
        exact
        layout={MainLayout}
        path="/rooms"
      />
      <RouteWithLayout
        component={RoomDetailView}
        layout={MainLayout}
        path="/room/:roomName"
      />
      <RouteWithLayout
        component={RoomEditView}
        layout={MainLayout}
        path="/room-edit/:roomName"
      />
      <RouteWithLayout
        component={RoomEditView}
        layout={MainLayout}
        path="/room-new"
      />
      <RouteWithLayout
        component={ConferenceHistoryView}
        exact
        layout={MainLayout}
        path="/conferences"
      />
      <RouteWithLayout
        component={RecordingListView}
        exact
        layout={MainLayout}
        path="/recordings"
      />
      <RouteWithLayout
        component={TradeConfirmView}
        exact
        layout={MainLayout}
        path="/trade/info/:tradeNo"
      />
      <RouteWithLayout
        component={TradeCenterView}
        layout={MainLayout}
        path="/trade/:tab"
      />
      <RouteWithLayout
        component={TradeCenterView}
        layout={MainLayout}
        path="/trade"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/passport/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/passport/sign-in"
      />
      <RouteWithLayout
        component={ResetView}
        exact
        layout={MinimalLayout}
        path="/passport/resetpw"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
