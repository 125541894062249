import React from 'react';

// Material components
import { SvgIcon } from '@material-ui/core';

export default function ConferenceHistory(props) {
  return (
    <SvgIcon
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M608.249978 338.524965a11.588321 11.588321 0 0 0-16.221622 2.271027L408.237384 583.785745a11.55385 11.55385 0 0 0 18.452094 13.91004l183.831527-242.999891a11.588321 11.588321 0 0 0-2.271027-16.170929z" />
      <path d="M511.994931 0C229.373729 0 0 229.373729 0 511.994931s229.373729 511.994931 511.994931 511.99493 511.994931-229.373729 511.99493-511.99493S794.616133 0 511.994931 0z m260.559796 594.289245a18.441956 18.441956 0 0 1-18.452094 18.452094H560.122454a18.452095 18.452095 0 1 1 0.060831-36.904189h193.929486a18.441956 18.441956 0 0 1 18.441956 18.452095zM546.009643 210.434986a46.221483 46.221483 0 0 1 64.714132-8.952308l62.635736 47.326581a46.150513 46.150513 0 0 1 8.952308 64.643162l-5.545767 7.350423-136.302175-103.007297zM251.272918 742.088494L273.141692 574.853714l133.098405 100.543639z m30.151939-181.89507L532.109741 228.826249l136.241345 103.007297L417.706755 663.210859z m472.758884 181.763269H467.80115a18.452095 18.452095 0 1 1 0-36.904189h286.382591a18.452095 18.452095 0 1 1 0 36.904189z" />
    </SvgIcon>
  );
}
