import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/styles';

import * as serviceWorker from './serviceWorker';
import App from './App';
import store from './store';
import theme from './theme';

export default function AppWithProviders() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          autoHideDuration={3000}
          dense
          maxSnack={1}
          preventDuplicate
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}

ReactDOM.render(<AppWithProviders />, document.getElementById('root'));

serviceWorker.unregister();
