import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

import UpgradePackage from './UpgradePackage';
import TradeList from './TradeList';
import PackageUsage from './PackageUsage';
import ApiDuration from './ApiDuration';
import RecordingDuration from './RecordingDuration';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    },
    flexGrow: 1,
    width: '100%'
  },
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  panel: {}
}));

const tabValues = [
  { label: '用户套餐', value: 'plan' },
  { label: '购买SDK时间包', value: 'api' },
  { label: '购买录制时间包', value: 'recording' },
  { label: '交易记录', value: 'list' },
  { label: '消费记录', value: 'usage' }
];

export default function TradeCenter({ history, match }) {
  const classes = useStyles();
  const value = match.params.tab || 'plan';

  function handleChange(event, newValue) {
    history.push(`/trade/${newValue}`);
  }

  let component = null;

  switch (value) {
    case 'list':
      component = <TradeList />;
      break;
    case 'api':
      component = <ApiDuration />;
      break;
    case 'recording':
      component = <RecordingDuration />;
      break;
    case 'usage':
      component = <PackageUsage />;
      break;
    default:
      component = <UpgradePackage />;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <AppBar
          color="default"
          position="static"
        >
          <Tabs
            aria-label="trade center"
            indicatorColor="primary"
            onChange={handleChange}
            scrollButtons="auto"
            textColor="primary"
            value={value}
            variant="scrollable"
          >
            {tabValues.map((tab, index) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </AppBar>
        <Box p={3}>{component}</Box>
      </Paper>
    </div>
  );
}

TradeCenter.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};
