import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Grid,
  Checkbox
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4),
    maxWidth: 500
  },
  checkbox: {
    fontSize: 14
  }
}));

const resolutions = [
  {
    value: 480,
    label: '标清'
  },
  {
    value: 720,
    label: '高清'
  }
];

function RoomEdit({ history, match, className }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const basePackage = useSelector(state => state.basePackage, shallowEqual);
  const edit = Boolean(match.params.roomName);
  const participantOptions = [];

  for (let i = 0; i < basePackage.participants; i++) {
    if (i === 0) {
      participantOptions.push({
        value: 0,
        label: '跟随套餐'
      });
    } else {
      participantOptions.push({
        value: i + 1,
        label: i + 1
      });
    }
  }

  const [values, setValues] = useState({
    roomName: match.params.roomName,
    subject: '',
    participantLimits: 0,
    lockPassword: '',
    resolution: 480,
    requireDisplayName: false,
    startWithAudioMuted: false,
    startWithVideoMuted: false
  });

  useEffect(() => {
    if (!match.params.roomName) {
      return;
    }

    (async function() {
      const roomInfo = await axios.post('/api/conference/info', {
        roomName: match.params.roomName
      });

      setValues(values => ({
        ...values,
        roomName: roomInfo.roomName,
        subject: roomInfo.subject,
        participantLimits: roomInfo.participantLimits,
        lockPassword: roomInfo.lockPassword || '',
        resolution: roomInfo.resolution || 360,
        requireDisplayName: roomInfo.requireDisplayName === undefined || Boolean(roomInfo.requireDisplayName),
        startWithAudioMuted: Boolean(roomInfo.startWithAudioMuted),
        startWithVideoMuted: Boolean(roomInfo.startWithVideoMuted)
      }));
    })();
  }, [match.params.roomName]);

  const handleChange = event => {
    const name = event.target.name;
    let value = event.target.value;
    const numberFields = ['resolution', 'participantLimits'];
    const checkboxes = ['requireDisplayName', 'startWithAudioMuted', 'startWithVideoMuted'];

    if (numberFields.includes(name)) {
      value = parseInt(value, 10);
    } else if (checkboxes.includes(name)) {
      value = event.target.checked;
    } else {
      value = value.trim();
    }
    setValues({
      ...values,
      [name]: value
    });
  };

  const handleSave = async event => {
    event.preventDefault();

    if (!values.roomName) {
      enqueueSnackbar('会议室名称不能为空', {
        variant: 'error'
      });
      return;
    }

    if (basePackage.participants < values.participantLimits) {
      enqueueSnackbar('分辨率格式错误', {
        variant: 'error'
      });
      return;
    }

    if (edit) {
      await axios.post('/api/conference/modify', values);

      enqueueSnackbar('信息已保存', {
        variant: 'success'
      });
    } else {
      await axios.post('/api/conference/create', values);

      enqueueSnackbar('会议室创建成功', {
        variant: 'success'
      });

      history.goBack();
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader title={edit ? '修改会议室' : '新建会议室'} />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                disabled={edit}
                fullWidth
                label="会议室名称"
                margin="dense"
                name="roomName"
                onChange={handleChange}
                required
                value={values.roomName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                label="会议室主题"
                margin="dense"
                name="subject"
                onChange={handleChange}
                required
                value={values.subject}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                label="清晰度"
                margin="dense"
                name="resolution"
                onChange={handleChange}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.resolution}
                variant="outlined"
              >
                {resolutions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                label="参会人数限制"
                margin="dense"
                name="participantLimits"
                onChange={handleChange}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={values.participantLimits}
                variant="outlined"
              >
                {participantOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                label="会议室密码（可选）"
                margin="dense"
                name="lockPassword"
                onChange={handleChange}
                value={values.lockPassword}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <div>
                <Checkbox
                  checked={values.requireDisplayName}
                  color="primary"
                  name="requireDisplayName"
                  onChange={handleChange}
                />
                <span className={classes.checkbox}>提示参会者进入会议室时输入名称</span>
              </div>
              <div>
                <Checkbox
                  checked={values.startWithAudioMuted}
                  color="primary"
                  name="startWithAudioMuted"
                  onChange={handleChange}
                />
                <span className={classes.checkbox}>参会者进入会议室时不自动打开麦克风</span>
              </div>
              <div>
                <Checkbox
                  checked={values.startWithVideoMuted}
                  color="primary"
                  name="startWithVideoMuted"
                  onChange={handleChange}
                />
                <span className={classes.checkbox}>参会者进入会议室时不自动打开摄像头</span>
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
          >
            {edit ? '保存' : '创建'}
          </Button>
          <Button
            color="primary"
            onClick={handleGoBack}
            style={{ marginLeft: '1rem' }}
            variant="outlined"
          >
            返回
          </Button>
        </CardActions>
      </form>
    </Card>
  );
}

RoomEdit.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object
};

export default withRouter(RoomEdit);
