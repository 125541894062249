export function copyToClipboard(value) {
  const el = document.createElement('textarea');

  el.value = value;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';

  document.body.appendChild(el);

  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;

  el.select();

  document.execCommand('copy');
  document.body.removeChild(el);

  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}

export function translateResolution(value) {
  if (!value || value < 720) {
    return '标清';
  }

  if (value < 1080) {
    return '高清';
  }

  return '超清';
}
