import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, Grid } from '@material-ui/core';
import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';

const useStyles = makeStyles(theme => ({
  paper: {
    // backgroundColor: '#f4f6f8'
    padding: theme.spacing(2)
  },
  title: {
    paddingBottom: theme.spacing(0.5)
  },
  itemName: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  itemValue: {
    padding: theme.spacing(1),
    textAlign: 'center'
  }
}));

export default function BasePackage() {
  const classes = useStyles();
  const basePackage = useSelector(state => state.basePackage, shallowEqual);

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justify="center"
      >
        <Grid
          container
          direction="column"
          item
          xs={6}
        >
          <Grid
            className={classes.itemName}
            item
          >
            <Typography variant="h6">会议方数</Typography>
          </Grid>
          <Grid
            className={classes.itemValue}
            item
          >
            <Typography
              color="primary"
              variant="h4"
            >
              {basePackage.participants}方
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          item
          xs={6}
        >
          <Grid
            className={classes.itemName}
            item
          >
            <Typography variant="h6">套餐结束时间</Typography>
          </Grid>
          <Grid
            className={classes.itemValue}
            item
          >
            <Typography
              color="primary"
              variant="h4"
            >
              {basePackage.expiresAt ? moment(basePackage.expiresAt).format('YYYY-MM-DD HH:mm:ss') : '免费套餐'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
