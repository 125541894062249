import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2)
  },
  item: {
    padding: theme.spacing(1)
  }
}));

export default function BaseAttention() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        variant="h5"
      >
        注意事项
      </Typography>

      <Paper className={classes.paper}>
        <Typography
          className={classes.item}
          variant="body1"
        >
          1.
          使用SDK方式创建会议时参会人数不受套餐限制，也不会占用套餐方数，仅消耗SDK时间包。
        </Typography>
        <Typography
          className={classes.item}
          variant="body1"
        >
          2.
          SDK时间消耗的计算公式，标清会议：会议时长*参会方数；高清会议：2*会议时长*参会方数；例如：4人开1小时高清会议，将消耗SDK时间2*60*4=480（分钟）
        </Typography>
        <Typography
          className={classes.item}
          variant="body1"
        >
          3.
          优先消费套餐内赠送时间包，当耗费时间超出套餐内时长时，下次购买将先行抵扣已超出的部分
        </Typography>
      </Paper>
    </div>
  );
}
