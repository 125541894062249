import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Typography, Grid, Button } from '@material-ui/core';
import { DashboardIcon, ConferenceIcon, ConferenceHistoryIcon, RecordingIcon, TradeIcon, AccountIcon } from 'icons';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  wechat: {
    verticalAlign: 'text-top'
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: '数据总览',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: '会议室',
      href: '/rooms',
      icon: <ConferenceIcon />
    },
    {
      title: '会议记录',
      href: '/conferences',
      icon: <ConferenceHistoryIcon />
    },
    {
      title: '录制管理',
      href: '/recordings',
      icon: <RecordingIcon />
    },
    {
      title: '费用中心',
      href: '/trade',
      icon: <TradeIcon />
    },
    {
      title: '账户信息',
      href: '/account',
      icon: <AccountIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
        <Divider className={classes.divider} />
        <div className={classes.phone}>
          <Typography
            component="span"
            variant="body1"
          >
            技术咨询
          </Typography>
          <Typography
            color="primary"
            component="h3"
            variant="body1"
          >
            <b>电话</b>：15626215621
          </Typography>
          <Typography
            color="primary"
            component="h3"
            variant="body1"
          >
            <b>Q Q</b>：
            <Button
              color="primary"
              href="http://wpa.qq.com/msgrd?v=3&uin=125988575&site=qq&menu=yes"
              size="small"
              variant="outlined"
            >
              点我咨询
            </Button>
            {/* <a  href="http://wpa.qq.com/msgrd?v=3&uin=125988575&site=qq&menu=yes">125988575</a> */}
          </Typography>
          <Typography
            className="wechat"
            color="primary"
            component="h3"
            variant="body1"
          >
            <Grid
              alignItems="start"
              container
              direction="row"
            >
              <b>微信：</b>
              <img
                alt="Logo"
                height="100"
                src="https://cdn.jhmeeting.com/www/wechat_tech.jpeg"
                width="100"
              />
            </Grid>
          </Typography>
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
