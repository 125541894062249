import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import Axios from 'axios';
import TradeDetail from './TradeDetail';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(3)
    },
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    width: 650
  }
}));

function TradeConfirm({ history, match }) {
  const classes = useStyles();
  const [info, setTradeInfo] = useState({});
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    (async () => {
      const values = await Axios.post('/api/trade/info', {
        tradeNo: match.params.tradeNo
      });
      setTradeInfo(values);
    })();
  }, [match]);

  const handleSubmit = () => {
    setOpen(true);
  };

  function handleClose() {
    setOpen(false);
  }

  function handleSuccess() {
    history.replace('/dashboard');
  }

  let typeDescription = '';

  switch (info.type) {
    case 'base_upgrade':
      typeDescription = '升级套餐';
      break;
    case 'recording_duration':
      typeDescription = '录制时间包';
      break;
    case 'api_duration':
      typeDescription = 'SDK时间包';
      break;
    default:
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardHeader title="确认订单" />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={3}
              xs={6}
            >
              <Typography variant="body1">订单号：</Typography>
            </Grid>
            <Grid
              item
              md={9}
              xs={6}
            >
              <Typography
                color="secondary"
                variant="h5"
              >
                {info.tradeNo}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <TradeDetail
                detail={info.detail}
                type={info.type}
              />
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <Typography variant="body1">订单金额：</Typography>
            </Grid>
            <Grid
              item
              md={9}
              xs={6}
            >
              <Typography
                color="secondary"
                variant="h5"
              >
                {info.amount}
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={6}
            >
              <Typography variant="body1">订单类型：</Typography>
            </Grid>
            <Grid
              item
              md={9}
              xs={6}
            >
              <Typography
                color="secondary"
                variant="h5"
              >
                {typeDescription}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={info.status !== 'pending'}
            href={info.payUrl}
            onClick={handleSubmit}
            target="_blank"
            variant="outlined"
          >
            支付
          </Button>
        </CardActions>
      </Card>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">等待支付</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            请在新打开的页面进行充值，充值完成后，根据您的情况点击下面按钮。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            onClick={handleClose}
          >
            支付失败
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={handleSuccess}
          >
            支付成功
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

TradeConfirm.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export default withRouter(TradeConfirm);
