import React from 'react';

// Material components
import { SvgIcon } from '@material-ui/core';

export default function Trade(props) {
  return (
    <SvgIcon
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M512 1024a512 512 0 1 1 512-512 512 512 0 0 1-512 512z m194.925714-519.68a54.857143 54.857143 0 0 0 0-109.714286h-113.005714l113.371429-113.371428a54.857143 54.857143 0 0 0-77.531429-77.531429L512 321.462857l-117.028571-117.028571a54.857143 54.857143 0 1 0-77.531429 77.531428l112.64 112.64H317.074286a54.857143 54.857143 0 0 0 0 109.714286h140.068571v73.142857H317.074286a54.857143 54.857143 0 0 0 0 109.714286h140.068571v94.354286a54.857143 54.857143 0 0 0 109.714286 0v-94.354286h140.068571a54.857143 54.857143 0 0 0 0-109.714286H566.857143v-73.142857z" />
    </SvgIcon>
  );
}
