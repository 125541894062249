import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2)
  },
  item: {
    padding: theme.spacing(1)
  },
  important: {
    color: theme.palette.primary.main
  }
}));

export default function BaseAttention() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        variant="h5"
      >
        注意事项
      </Typography>

      <Paper className={classes.paper}>
        <Typography
          className={classes.item}
          variant="body1"
        >
          1. 多个会议室同时开会，总人数不能超过当前套餐人数
        </Typography>
        <Typography
          className={classes.item}
          variant="body1"
        >
          2. 使用简会官网开视频会议不会消耗SDK时间
        </Typography>
        <Typography
          className={classes.item}
          variant="body1"
        >
          3. 如果您购买的方数超过100方,请您联系我们的销售人员
        </Typography>

        <Typography
          className={classes.item}
          variant="h6"
        >
          4. 您也可以通过网上银行，银行转账等方式进行汇款。
          <Typography
            className={classes.item}
            variant="body1"
          >
            开户行：
            <span className={classes.important}>
              招商银行股份有限公司广州林和路支行
            </span>
            <br />
            户名：
            <span className={classes.important}>
              广州市简会信息科技有限公司
            </span>
            <br />
            账号： <span className={classes.important}>120911385810801</span>
            <br />
            为了您的合法权益，转账时请备注您在简会注册的手机号。
            银行转账会需要至少3个工作日到账，期间请耐心等待，如遇到问题可以联系：15626215621。
          </Typography>
        </Typography>
      </Paper>
    </div>
  );
}
