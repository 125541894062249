import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
  LinearProgress
} from '@material-ui/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import axios from 'axios';
import { FETCH_USER } from 'actions';

const useStyles = makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 100,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  input: {
    display: 'none'
  }
}));

const itemScore = 20;

const AccountProfile = props => {
  const { className, ...rest } = props;
  const user = useSelector(state => state.user, shallowEqual);
  const dispatch = useDispatch();
  const classes = useStyles();

  let completeness = itemScore;

  if (user.email) {
    completeness += itemScore;
  }

  if (user.companyName) {
    completeness += itemScore;
  }

  if (user.companySite) {
    completeness += itemScore;
  }

  if (user.avatarUrl) {
    completeness += itemScore;
  }

  const inputRef = React.createRef();

  const uploadAvatar = () => {
    inputRef.current.click();
  };

  const handleFileChanged = async ev => {
    const target = ev.target;

    if (target.files.length === 0) {
      return;
    }

    const formdata = new FormData();

    formdata.append('file', target.files[0]);

    const result = await axios.post('/api/public/uploadAvatar', formdata);

    await axios.post('/api/user/updateAvatarUrl', {avatarUrl: result.url});

    dispatch({ type: FETCH_USER, user: { ...user, avatarUrl: result.url } });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="h2"
            >
              {user.name}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {user.mobile}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {user.email}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {user.companyName}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body2"
            >
              {user.companySite}
            </Typography>
          </div>
          <Avatar
            className={classes.avatar}
            src={user.avatarUrl || '/images/avatar.svg'}
          />
        </div>
        <div className={classes.progress}>
          <Typography variant="body1">资料完整度: {completeness}%</Typography>
          <LinearProgress
            value={completeness}
            variant="determinate"
          />
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <input
          accept="image/*"
          className={classes.input}
          id="avatar-button-file"
          onChange={handleFileChanged}
          ref={inputRef}
          type="file"
        />
        <label htmlFor="avatar-button-file">
          <Button
            className={classes.uploadButton}
            color="primary"
            onClick={uploadAvatar}
            variant="text"
          >
            上传头像
          </Button>
        </label>
      </CardActions>
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default AccountProfile;
