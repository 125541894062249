import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent, CardActions, Divider, Button, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import axios from 'axios';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const { className, ...rest } = props;
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [values, setValues] = useState({
    oldPassword: '',
    password: '',
    confirm: ''
  });

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value.trim()
    });
  };

  const handleUpdate = async event => {
    event.preventDefault();

    if (!values.password || !values.confirm) {
      return;
    }
    if (values.password.length < 6) {
      enqueueSnackbar('密码长度不少于6位', {
        variant: 'error'
      });
      return;
    }
    if (values.password !== values.confirm) {
      enqueueSnackbar('密码不一致', {
        variant: 'error'
      });
      return;
    }

    await axios.post('/api/user/updatePassword', {
      oldPassword: values.oldPassword,
      newPassword: values.password
    });

    enqueueSnackbar('密码已修改', {
      variant: 'success'
    });
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          subheader="修改密码"
          title="密码"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="旧密码"
            name="oldPassword"
            onChange={handleChange}
            type="password"
            value={values.oldPassword}
            variant="outlined"
          />
          <TextField
            fullWidth
            helperText="长度不少于6位"
            label="新密码"
            name="password"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="确认密码"
            name="confirm"
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            onClick={handleUpdate}
            variant="outlined"
          >
            修改
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
