import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { PlanPackage } from '../../../Package';
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(1)
  }
}));

export default function PlanPackageWrapper(props) {
  const classes = useStyles();
  const { isApiPackage } = props;
  const to = isApiPackage ? '/trade/api' : '/trade/recording';

  return (
    <div className={classes.root}>
      <Grid
        alignItems="center"
        className={classes.title}
        container
        justify="space-between"
      >
        <Grid item>
          <Typography variant="h5">
            {isApiPackage ? 'SDK时间包' : '录制/直播时间包'}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            component={RouterLink}
            to={to}
            variant="outlined"
          >
            购买
          </Button>
        </Grid>
      </Grid>

      <PlanPackage {...props} />
    </div>
  );
}

PlanPackageWrapper.propTypes = {
  isApiPackage: PropTypes.bool
};
