import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Typography, TextField, InputAdornment, IconButton } from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import copy from 'clipboard-copy';

const useStyles = makeStyles(() => ({
  title: {
    flex: '0 0 auto'
  }
}));

function RoomInfo({ roomInfo }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const roomWebsite = useSelector(state => state.config.roomWebsite);
  const roomUrl = `${roomWebsite}/${roomInfo.roomName}`;
  const moderatorUrl = `${roomUrl}?jwt=${roomInfo.token}`;

  const handleCopy = value => {
    copy(value).then(() => {
      enqueueSnackbar('链接已复制', { variant: 'success' });
    });
  };

  return (
    <Grid
      alignItems="center"
      container
      spacing={3}
    >
      <Grid
        item
        md={2}
        xs={6}
      >
        <Typography
          className={classes.title}
          variant="subtitle2"
        >
          会议室名称：
        </Typography>
      </Grid>
      <Grid
        item
        md={10}
        xs={6}
      >
        <Typography
          className={classes.title}
          color="primary"
          variant="subtitle1"
        >
          {roomInfo.roomName}
        </Typography>
      </Grid>

      <Grid
        item
        md={2}
        xs={6}
      >
        <Typography
          className={classes.title}
          variant="subtitle2"
        >
          容量：
        </Typography>
      </Grid>
      <Grid
        item
        md={10}
        xs={6}
      >
        <Typography
          className={classes.title}
          color="primary"
          variant="subtitle1"
        >
          {roomInfo.participantLimits || '跟随套餐'}
        </Typography>
      </Grid>

      <Grid
        item
        md={2}
        xs={6}
      >
        <Typography
          className={classes.title}
          variant="subtitle2"
        >
          清晰度：
        </Typography>
      </Grid>
      <Grid
        item
        md={10}
        xs={6}
      >
        <Typography
          className={classes.title}
          color="primary"
          variant="subtitle1"
        >
          {roomInfo.resolution < 720 ? '标清' : '高清'}
        </Typography>
      </Grid>

      <Grid
        item
        md={2}
        xs={6}
      >
        <Typography
          className={classes.title}
          variant="subtitle2"
        >
          会议室密码：
        </Typography>
      </Grid>
      <Grid
        item
        md={10}
        xs={6}
      >
        <Typography
          className={classes.title}
          color={roomInfo.lockPassword ? 'primary' : 'textSecondary'}
          variant="subtitle1"
        >
          {roomInfo.lockPassword || '无'}
        </Typography>
      </Grid>

      <Grid
        item
        md={2}
        xs={12}
      >
        <Typography
          className={classes.title}
          variant="subtitle2"
        >
          主持人链接：
        </Typography>
      </Grid>
      <Grid
        alignItems="center"
        container
        item
        md={10}
        spacing={1}
        xs={12}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            disabled
            fullWidth
            // eslint-disable-next-line react/jsx-sort-props
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle copy"
                    edge="end"
                    onClick={() => {
                      handleCopy(moderatorUrl);
                    }}
                    title="拷贝"
                  >
                    <FileCopy />
                  </IconButton>
                </InputAdornment>
              )
            }}
            type="text"
            value={moderatorUrl}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <Button
            color="secondary"
            href={`/api/public/downloadSDKDemo?roomName=${encodeURIComponent(roomInfo.roomName)}&jwt=${roomInfo.token}`}
            target="_blank"
          >
            下载SDK接入Demo
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        md={2}
        xs={12}
      >
        <Typography
          className={classes.title}
          variant="subtitle2"
        >
          参会者链接：
        </Typography>
      </Grid>
      <Grid
        alignItems="center"
        container
        item
        md={10}
        spacing={1}
        xs={12}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <TextField
            disabled
            fullWidth
            // eslint-disable-next-line react/jsx-sort-props
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle copy"
                    edge="end"
                    onClick={() => {
                      handleCopy(roomUrl);
                    }}
                    title="拷贝"
                  >
                    <FileCopy />
                  </IconButton>
                </InputAdornment>
              )
            }}
            type="text"
            value={roomUrl}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <Button
            color="secondary"
            href={`/api/public/downloadSDKDemo?roomName=${encodeURIComponent(roomInfo.roomName)}`}
            target="_blank"
          >
            下载SDK接入Demo
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

RoomInfo.propTypes = {
  roomInfo: PropTypes.object.isRequired
};

export default RoomInfo;
