import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Grid,
  TextField,
  Slider
} from '@material-ui/core';
import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { BasePackage } from '../Package';
import BaseAttention from './BaseAttention';
import axios from 'axios';

const MIN_PARTICIPANTS = 3;
const MAX_PARTICIPANTS = 100;
const DEFAULT_MONTHS = 3;

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  upgrade: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3)
  },
  durationBtn: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1)
    }
  },
  selectParticipants: {
    width: 150
  },
  slider: {
    widows: 200
  },
  input: {
    height: 30,
    width: 65
  },
  amountWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  amount: {
    fontSize: '24px',
    fontWeight: 900,
    marginRight: theme.spacing(1)
  }
}));

function expired(expiresAt) {
  return expiresAt && moment(expiresAt).subtract(moment) <= 0;
}

function UpgradePackage({ history }) {
  const classes = useStyles();
  const basePackage = useSelector(state => state.basePackage, shallowEqual);
  const baseParticipants = basePackage.participants;
  const [values, setValues] = useState({ months: DEFAULT_MONTHS, participants: MIN_PARTICIPANTS });
  const [detail, setDetail] = useState({
    apiDuration: 0,
    recordingDuration: 0,
    amount: 0,
    price: 0,
    expiresAt: null,
  });

  useEffect(() => {
    // 等baseParticipants可用
    if (!baseParticipants || baseParticipants < MIN_PARTICIPANTS) {
      return;
    }
    setValues(values => ({
      ...values,
      participants: baseParticipants
    }));
  }, [baseParticipants]);

  // Debounce callback
  const [debouncedFetchAmount] = useDebouncedCallback(
    async () => {
      const detail = await axios.post('/api/trade/calcBase', values);

      setDetail(detail);
    },
    // delay in ms
    500
  );

  useEffect(() => {
    debouncedFetchAmount();
  }, [values, debouncedFetchAmount]);

  const handleMonthsClick = months => () => {
    // 已付费没过期的套餐可以取消选择
    if (
      values.months === months &&
      basePackage.paid &&
      !expired(basePackage.expiresAt)
    ) {
      setValues({ ...values, months: 0 });
    } else {
      setValues({ ...values, months });
    }
  };

  const handleSliderChange = (e, participants) => {
    if (
      participants >= basePackage.participants ||
      !basePackage.paid ||
      expired(basePackage.expiresAt)
    ) {
      setValues(values => ({ ...values, participants }));
    }
  };

  const handleInputChange = event => {
    setValues({ ...values, participants: parseInt(event.target.value) });
  };

  const handleUpgrade = async event => {
    event.preventDefault();

    const { tradeNo } = await axios.post(
      '/api/trade/upgradeBasePackage',
      values
    );

    history.push(`/trade/info/${tradeNo}`);
  };

  const handleBlur = () => {
    if (values.participants < MIN_PARTICIPANTS) {
      setValues({ ...values, participants: MIN_PARTICIPANTS });
    } else if (values.participants > MAX_PARTICIPANTS) {
      setValues({ ...values, participants: MAX_PARTICIPANTS });
    }
  };

  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        variant="h5"
      >
        当前套餐
      </Typography>
      <BasePackage />

      <Typography
        className={classes.upgrade}
        variant="h5"
      >
        升级套餐
      </Typography>
      <Card>
        <form>
          <CardContent>
            <Grid
              alignItems="center"
              container
              spacing={2}
            >
              <Grid
                item
                md={2}
                xs={12}
              >
                <Typography variant="body1">购买时长：</Typography>
              </Grid>
              <Grid
                item
                md={10}
                xs={12}
              >
                <Button
                  className={classes.durationBtn}
                  color={values.months === 3 ? 'primary' : 'default'}
                  disableRipple
                  onClick={handleMonthsClick(3)}
                  size="large"
                  variant="outlined"
                >
                  3个月
                </Button>
                <Button
                  className={classes.durationBtn}
                  color={values.months === 6 ? 'primary' : 'default'}
                  disableRipple
                  onClick={handleMonthsClick(6)}
                  size="large"
                  variant="outlined"
                >
                  半年
                </Button>
                <Button
                  color={values.months === 12 ? 'primary' : 'default'}
                  disableRipple
                  onClick={handleMonthsClick(12)}
                  size="large"
                  variant="outlined"
                >
                  一年
                </Button>
              </Grid>
              <Grid
                item
                md={2}
                xs={6}
              >
                <Typography variant="body1">套餐方数：</Typography>
              </Grid>
              <Grid
                container
                item
                md={10}
                spacing={2}
                xs={6}
              >
                <Grid
                  item
                  md={4}
                  xs={9}
                >
                  <Slider
                    aria-labelledby="input-slider"
                    className={classes.slider}
                    marks={[
                      { value: MIN_PARTICIPANTS, label: MIN_PARTICIPANTS }, 
                      { value: MAX_PARTICIPANTS, label: MAX_PARTICIPANTS }
                    ]}
                    max={MAX_PARTICIPANTS}
                    min={MIN_PARTICIPANTS}
                    onChange={handleSliderChange}
                    value={values.participants}
                    valueLabelDisplay="auto"
                  />
                </Grid>
                <Grid
                  item
                  md={8}
                  xs={3}
                >
                  <TextField
                    InputProps={{
                      className: classes.input,
                      step: 1,
                      min: MIN_PARTICIPANTS,
                      max: 100,
                      type: 'number',
                      'aria-labelledby': 'input-slider'
                    }}
                    margin="dense"
                    onBlur={handleBlur}
                    onChange={handleInputChange}
                    value={values.participants}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                md={2}
                xs={6}
              >
                <Typography variant="body1">支付方式：</Typography>
              </Grid>
              <Grid
                item
                md={10}
                xs={6}
              >
                <img
                  alt="alipay"
                  height={60}
                  src="/images/alipay.svg"
                  width={80}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={6}
              >
                <Typography variant="body1">赠送SDK时间：</Typography>
              </Grid>

              <Grid
                item
                md={10}
                xs={6}
              >
                <Typography
                  color="secondary"
                  variant="h5"
                >
                  {detail.apiDuration / 60}分钟
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={6}
              >
                <Typography variant="body1">赠送录制时间：</Typography>
              </Grid>

              <Grid
                item
                md={10}
                xs={6}
              >
                <Typography
                  color="secondary"
                  variant="h5"
                >
                  {detail.recordingDuration / 60}分钟
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={6}
              >
                <Typography variant="body1">套餐过期时间：</Typography>
              </Grid>

              <Grid
                item
                md={10}
                xs={6}
              >
                <Typography
                  color="secondary"
                  variant="h5"
                >
                  {detail.expiresAt && moment(detail.expiresAt).format('YYYY-MM-DD HH:mm:ss')}
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={6}
              >
                <Typography variant="body1">每方每月价格：</Typography>
              </Grid>

              <Grid
                item
                md={10}
                xs={6}
              >
                <Typography
                  color="secondary"
                  variant="h5"
                >
                  {detail.price}元
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Grid
              container
              justify="space-between"
            >
              <Grid
                className={classes.amountWrapper}
                item
              >
                <Typography variant="body1">总金额：</Typography>
                <Typography
                  className={classes.amount}
                  color="primary"
                  variant="body1"
                >
                  {detail.amount}
                </Typography>
                <Typography variant="body1">元</Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={handleUpgrade}
                  variant="outlined"
                >
                  提交订单
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </form>
      </Card>
      <BaseAttention />
    </div>
  );
}

UpgradePackage.propTypes = {
  history: PropTypes.object
};

export default withRouter(UpgradePackage);
