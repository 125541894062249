import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Paper,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { useSelector, shallowEqual } from 'react-redux';
import { useSnackbar } from 'notistack';
import FileCopy from '@material-ui/icons/FileCopy';
import copy from 'clipboard-copy';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  title: {
    marginTop: theme.spacing(3)
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    width: '100%',
    overflowX: 'auto'
  },
  container: {
    textAlign: 'center'
  }
}));

export default function ApiKey() {
  const classes = useStyles();
  const apiKey = useSelector(state => state.user.apiKey, shallowEqual);
  const { enqueueSnackbar } = useSnackbar();
  const handleCopy = () => {
    copy(apiKey).then(() => {
      enqueueSnackbar('已复制', { variant: 'success', preventDuplicate: true });
    });
  };

  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        variant="h4"
      >
        API密钥
      </Typography>
      <Paper className={classes.paper}>
        <Grid
          alignItems="center"
          className={classes.container}
          container
          justify="center"
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <TextField
              disabled
              fullWidth
              // eslint-disable-next-line react/jsx-sort-props
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle copy"
                      edge="end"
                      onClick={handleCopy}
                      title="拷贝"
                    >
                      <FileCopy />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              type="text"
              value={apiKey || ''}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <Button
              color="secondary"
              href="#link"
              variant="outlined"
            >
              文档中心
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
