import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2)
  },
  item: {
    padding: theme.spacing(1)
  }
}));

export default function RecordingAttention() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        variant="h5"
      >
        注意事项
      </Typography>

      <Paper className={classes.paper}>
        <Typography
          className={classes.item}
          variant="body1"
        >
          1. 使用服务器录制和直播功能将消耗录制时间，消耗时长 = 录制或直播时长。
        </Typography>
        <Typography
          className={classes.item}
          variant="body1"
        >
          2.
          优先消费套餐内赠送时间包，当耗费时间超出套餐内时长时，下次购买将先行抵扣已超出的部分
        </Typography>
      </Paper>
    </div>
  );
}
