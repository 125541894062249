import React, { useState, useEffect } from 'react';
import { Link, Paper, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import moment from 'moment';
import { useSelector, shallowEqual } from 'react-redux';
import { translateResolution } from 'common/helper';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center'
  },
  empty: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center'
  },
  tableWrap: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  }
}));

function formatDuation(row) {
  const endTime = row.etime ? moment(row.etime) : moment();

  return moment.utc(moment(endTime).diff(row.ctime)).format('HH:mm:ss');
}

export default function ConfereceRunning() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const config = useSelector(state => state.config, shallowEqual);

  useEffect(() => {
    (async function() {
      let { data } = await axios.post('/api/conference/running', {});

      setRows(data || []);
    })();
  }, []);

  if (rows.length === 0) {
    return (
      <Paper className={classes.root}>
        <Typography
          className={classes.empty}
          variant="h4"
        >
          您当前没有进行中的会议
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper className={classes.root}>
      <Typography
        className={classes.title}
        variant="h4"
      >
        您当前有{rows.length}场会议正在进行
      </Typography>
      <div className={classes.tableWrap}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>会议室名称</TableCell>
              <TableCell align="center">会议时长</TableCell>
              <TableCell align="center">开始时间</TableCell>
              <TableCell align="center">清晰度</TableCell>
              <TableCell align="center">当前人数</TableCell>
              <TableCell align="center">最高人数</TableCell>
              <TableCell align="center">操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  component="th"
                  scope="row"
                >
                  {row.roomName}
                </TableCell>
                <TableCell align="center">{formatDuation(row)}</TableCell>
                <TableCell align="center">{moment(row.ctime).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                <TableCell align="center">{translateResolution(row.resolution)}</TableCell>
                <TableCell align="center">{row.participants}</TableCell>
                <TableCell align="center">{row.maxParticipants}</TableCell>
                <TableCell align="center">
                  <Link
                    href={`${config.roomWebsite}/${row.roomName}`}
                    target="_blank"
                  >
                    加入
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}
