import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import moment from 'moment';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import lightBlue from '@material-ui/core/colors/lightBlue';
import TradeDetail from './TradeDetail';

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  title: {
    flex: '0 0 auto'
  },
  table: {
    minWidth: 650
  }
}));

export default function TradeList() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    total: 0
  });
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState({});

  const handleChangeRowsPerPage = async event => {
    setPagination({ ...pagination, perPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  useEffect(() => {
    (async () => {
      const { data, total } = await axios.post('/api/trade/records', {
        page: pagination.page,
        perPage: pagination.perPage
      });

      const rows = data || [];

      rows.forEach(row => {
        switch (row.type) {
          case 'base_upgrade':
            row.typeDescription = '升级套餐';
            break;
          case 'recording_duration':
            row.typeDescription = '录制时间包';
            break;
          case 'api_duration':
            row.typeDescription = 'SDK时间包';
            break;
          default:
        }

        switch (row.status) {
          case 'pending':
            row.statusDescription = '等待支付';
            row.statusColor = lightBlue[500];
            break;
          case 'success':
            row.statusDescription = '交易成功';
            row.statusColor = green[500];
            break;
          default:
            row.statusDescription = '交易失败';
            row.statusColor = red[500];
        }
      });

      setPagination(p => ({ ...p, total }));
      setRows(rows);
    })();
  }, [pagination.page, pagination.perPage]);

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleShowDetail = row => () => {
    setRow(row);
    setOpen(true);
  };

  return (
    <Paper className={classes.paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>订单号</TableCell>
            <TableCell align="center">类型</TableCell>
            <TableCell align="center">总金额（元）</TableCell>
            <TableCell align="center">状态</TableCell>
            <TableCell align="center">下单时间</TableCell>
            <TableCell align="center">操作</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.tradeNo}>
              <TableCell
                component="th"
                scope="row"
              >
                {row.tradeNo}
              </TableCell>
              <TableCell align="center">{row.typeDescription}</TableCell>
              <TableCell align="center">{row.amount.toFixed(2)}</TableCell>
              <TableCell align="center">
                <Typography style={{ color: row.statusColor }}>
                  {row.statusDescription}
                </Typography>
              </TableCell>
              <TableCell align="center">
                {moment(row.ctime).format('YYYY-MM-DD HH:mm:ss')}
              </TableCell>
              <TableCell align="center">
                {row.status === 'pending' ? (
                  <Button
                    color="primary"
                    href={`/trade/info/${row.tradeNo}`}
                  >
                    继续支付
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    onClick={handleShowDetail(row)}
                  >
                    详情
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        backIconButtonProps={{
          'aria-label': '上一页'
        }}
        component="div"
        count={pagination.total}
        labelRowsPerPage="每页"
        nextIconButtonProps={{
          'aria-label': '下一页'
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={pagination.page}
        rowsPerPage={pagination.perPage}
        rowsPerPageOptions={[10, 20, 50]}
      />

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        fullWidth
        onClose={handleDialogClose}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">订单详情</DialogTitle>
        <DialogContent>
          <TradeDetail
            detail={row.detail}
            type={row.type}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="default"
            onClick={handleDialogClose}
          >
            关闭
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
