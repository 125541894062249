import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Button, Grid, Typography, Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

import RoomInfo from './RoomInfo';
import ConferenceHistory from '../../ConferenceHistory';
import RecordingList from '../../RecordingList';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    }
  },
  detail: {
    // maxWidth: 500,
  },
  conferenceList: {
    marginTop: theme.spacing(2)
  }
}));

function RoomDetail({ history, match }) {
  const classes = useStyles();
  const { roomName } = match.params;
  const [roomInfo, setRoomInfo] = useState({});

  const roomWebsite = useSelector(state => state.config.roomWebsite);
  const roomUrl = `${roomWebsite}/${roomInfo.roomName}`;
  const moderatorUrl = `${roomUrl}?jwt=${roomInfo.token}`;

  useEffect(() => {
    (async function() {
      const [roomInfo, { token }] = await Promise.all([
        axios.post('/api/conference/info', { roomName }),
        axios.post('/api/conference/token', { roomName, expiresAt: -1 })
      ]);

      roomInfo.token = token;

      setRoomInfo(roomInfo);
    })();
  }, [roomName]);

  const handleGoback = () => {
    history.goBack();
  };

  return (
    <div className={classes.root}>
      <Card className={classes.detail}>
        <CardHeader
          title={
            <Grid
              alignItems="center"
              container
              justify="space-between"
            >
              <Grid item>
                <Typography variant="h4">会议室详情</Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={handleGoback}
                >
                  返回
                </Button>
              </Grid>
            </Grid>
          }
        />
        <Divider />
        <CardContent>
          <RoomInfo roomInfo={roomInfo} />
        </CardContent>
        <Divider />
        <CardActions>
          <Grid
            alignItems="center"
            container
            justify="space-between"
          >
            <Grid item>
              <Button
                color="primary"
                href={moderatorUrl}
                target="_blank"
                variant="outlined"
              >
                开始会议
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                component={RouterLink}
                to={`/room-edit/${roomInfo.roomName}`}
              >
                修改
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>

      <Card className={classes.conferenceList}>
        <CardHeader title="会议记录" />
        <Divider />
        <CardContent>
          <ConferenceHistory roomName={roomName} />
        </CardContent>
      </Card>

      <Card className={classes.conferenceList}>
        <CardHeader title="录制记录" />
        <Divider />
        <CardContent>
          <RecordingList roomName={roomName} />
        </CardContent>
      </Card>
    </div>
  );
}

RoomDetail.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
};

export default RoomDetail;
