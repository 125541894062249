import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link, AppBar, Toolbar, Hidden, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import axios from 'axios';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  title: {
    marginLeft: theme.spacing(2),
    color: 'white'
  },
  signOutText: {
    marginLeft: theme.spacing(1),
    color: 'white'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className, history, onSidebarOpen } = props;
  const classes = useStyles();
  const mainWebsite = useSelector(state => state.config.mainWebsite);

  const handleSignout = async () => {
    await axios.post('/api/passport/signOut');

    history.push('/passport/sign-in');
  };

  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar>
        <Link
          href={mainWebsite}
          target="_blank"
        >
          <img
            alt="Logo"
            height="45"
            src="/images/logo.svg"
            width="45"
          />
        </Link>
        <RouterLink to="/">
          <Typography
            className={classes.title}
            variant="h3"
          >
            简会后台管理
          </Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden smDown>
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={handleSignout}
          >
            <InputIcon />
            <Typography
              className={classes.signOutText}
              variant="body1"
            >
              退出
            </Typography>
          </IconButton>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
  onSidebarOpen: PropTypes.func
};

export default withRouter(Topbar);
