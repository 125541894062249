import React, { useLayoutEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import validate from 'validate.js';
import axios from 'axios';

import { chartjs } from './helpers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { FETCH_USER, FETCH_CONFIG, FETCH_BASE_PACKAGE } from './actions';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default function App() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    axios.interceptors.response.use(
      response => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        const { data } = response;

        if (data.errcode && data.errcode > 0) {
          if (data.errcode === 40001) {
            const { location } = browserHistory;

            if (!location.pathname.startsWith('/passport/')) {
              browserHistory.push('/passport/sign-in');
            }
          } else if (data.errmsg) {
            enqueueSnackbar(data.errmsg, { variant: 'error' });
          }
          throw data;
        }
        return data;
      },
      error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    );

    (async function fetchConfig() {
      const config = await axios.post('/api/public/config');

      dispatch({ type: FETCH_CONFIG, config });
    })();

    async function fetchUser() {
      const userInfo = await axios.post('/api/user/info');

      dispatch({ type: FETCH_USER, user: userInfo });
    }

    async function fetchBasePackage() {
      const basePackage = await axios.post('/api/plan/basePackage');

      dispatch({ type: FETCH_BASE_PACKAGE, basePackage });
    }

    fetchUser().then(fetchBasePackage);

    return browserHistory.listen(location => {
      if (location.pathname === '/' || location.pathname.endsWith('/dashboard')) {
        fetchUser().then(fetchBasePackage);
      }
    });
  });

  return (
    <Router history={browserHistory}>
      <Routes />
    </Router>
  );
}
