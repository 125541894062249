import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography } from '@material-ui/core';

import UserPlan from './UserPlan';
import PlanPackage from './PlanPackage';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  title: {
    marginTop: theme.spacing(3)
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    width: '100%',
    overflowX: 'auto'
  }
}));

export default function PackageSummary() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        variant="h4"
      >
        套餐使用情况
      </Typography>
      <Paper className={classes.paper}>
        <UserPlan />
        <PlanPackage isApiPackage />
        <PlanPackage />
      </Paper>
    </div>
  );
}
