import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

function TradeDetail({ detail, type }) {
  if (!detail) {
    return null;
  }

  let duration = detail.apiDuration || detail.recordingDuration || 0;

  switch (type) {
    case 'base_upgrade':
      return (
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={3}
            xs={6}
          >
            <Typography variant="body1">增加服务时长：</Typography>
          </Grid>
          <Grid
            item
            md={9}
            xs={6}
          >
            <Typography
              color="secondary"
              variant="h5"
            >
              {detail.months} 个月
            </Typography>
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
          >
            <Typography variant="body1">升级参会方为：</Typography>
          </Grid>
          <Grid
            item
            md={9}
            xs={6}
          >
            <Typography
              color="secondary"
              variant="h5"
            >
              {detail.participants} 方
            </Typography>
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
          >
            <Typography variant="body1">赠送SDK时长：</Typography>
          </Grid>
          <Grid
            item
            md={9}
            xs={6}
          >
            <Typography
              color="secondary"
              variant="h5"
            >
              {detail.apiDuration / 60} 分钟
            </Typography>
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
          >
            <Typography variant="body1">赠送录制时长：</Typography>
          </Grid>
          <Grid
            item
            md={9}
            xs={6}
          >
            <Typography
              color="secondary"
              variant="h5"
            >
              {detail.recordingDuration / 60} 分钟
            </Typography>
          </Grid>
        </Grid>
      );
    default:
      return (
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={3}
            xs={6}
          >
            <Typography variant="body1">
              购买{type === 'api_duration' ? 'SDK' : '录制'}时间包：
            </Typography>
          </Grid>
          <Grid
            item
            md={9}
            xs={6}
          >
            <Typography
              color="secondary"
              variant="h5"
            >
              {duration / 60} 分钟
            </Typography>
          </Grid>
        </Grid>
      );
  }
}

TradeDetail.propTypes = {
  detail: PropTypes.object,
  type: PropTypes.string
};

export default TradeDetail;
