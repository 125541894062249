import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Modal,
  Fade
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import moment from 'moment';
import withConfirm from 'material-ui-confirm';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    }
  },
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  title: {
    flex: '0 0 auto'
  },
  table: {
    minWidth: 650
  },
  delete: {
    color: 'red'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '90%',
    maxWidth: '80%'
  },
  closeIcon: {
    alignSelf: 'flex-end',
    marginRight: -20,
    color: 'white',
    cursor: 'pointer'
  },
  modalVideo: {
    objectFit: 'cover',
    height: '100%'
  }
}));

function readableBytes(bytes) {
  var i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
}

function RecordingList({ roomName, confirm }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, perPage: 10, total: 0 });
  const [modalState, setModalState] = useState({ open: false });

  useEffect(() => {
    (async function() {
      const { data, total } = await axios.post('/api/conference/recordings', {
        roomName,
        page: pagination.page,
        perPage: pagination.perPage
      });

      setPagination(p => ({ ...p, total }));
      setRows(data || []);
    })();
  }, [roomName, pagination.page, pagination.perPage, pagination.total]);

  const handleChangeRowsPerPage = async event => {
    setPagination({ ...pagination, perPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  const handlePlayVideo = url => () => {
    setModalState({ open: true, url });
  };

  const handleDelete = id => async () => {
    await axios.post('/api/conference/deleteRecording', { id });

    setPagination({ ...pagination, total: pagination.total - 1 });
  };

  const handleClose = () => {
    setModalState({ open: false });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>会议室名称</TableCell>
              <TableCell align="center">开始时间</TableCell>
              <TableCell align="center">录制时长</TableCell>
              <TableCell align="center">文件大小</TableCell>
              <TableCell align="center">操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell
                  component="th"
                  scope="row"
                >
                  {row.roomName}
                </TableCell>
                <TableCell align="center">{moment(row.ctime).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                <TableCell align="center">{moment.utc(row.duration * 1000).format('HH:mm:ss')}</TableCell>
                <TableCell align="center">{readableBytes(row.size || 0)}</TableCell>
                <TableCell align="center">
                  <Button
                    color="primary"
                    onClick={handlePlayVideo(row.downloadUrl)}
                  >
                    播放
                  </Button>
                  <Button
                    color="primary"
                    download
                    href={row.downloadUrl}
                    target="_blank"
                  >
                    下载
                  </Button>
                  <Button
                    className={classes.delete}
                    onClick={confirm(handleDelete(row.id), {
                      title: '提示',
                      description: '删除后将不可恢复，确认要删除吗？',
                      confirmationText: '删除',
                      cancellationText: '取消'
                    })}
                  >
                    删除
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          backIconButtonProps={{
            'aria-label': '上一页'
          }}
          component="div"
          count={pagination.total}
          labelRowsPerPage="每页"
          nextIconButtonProps={{
            'aria-label': '下一页'
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={pagination.page}
          rowsPerPage={pagination.perPage}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </Paper>
      <Modal
        aria-describedby="transition-modal-description"
        aria-labelledby="transition-modal-title"
        // eslint-disable-next-line react/jsx-sort-props
        BackdropComponent={Backdrop}
        // eslint-disable-next-line react/jsx-sort-props
        BackdropProps={{
          timeout: 500
        }}
        className={classes.modal}
        closeAfterTransition
        disableAutoFocus
        onClose={handleClose}
        open={modalState.open}
      >
        <Fade in={modalState.open}>
          <div className={classes.modalContent}>
            <CloseIcon
              className={classes.closeIcon}
              onClick={handleClose}
            />
            <video
              autoPlay
              className={classes.modalVideo}
              controls
              src={modalState.url}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

RecordingList.propTypes = {
  confirm: PropTypes.func,
  roomName: PropTypes.string
};

export default withConfirm(RecordingList);
