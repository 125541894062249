import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import { PlanPackage } from '../Package';
import RecordingAttention from './RecordingAttention';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  pay: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3)
  },
  button: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1)
    }
  },
  amountWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  amount: {
    fontSize: '24px',
    fontWeight: 900,
    marginRight: theme.spacing(1)
  }
}));

function RecordingDuration({ history }) {
  const classes = useStyles();
  const planConfig = useSelector(state => state.config.plan, shallowEqual);
  const [values, setValues] = useState({ amount: 0, price: 0, duration: 100 });

  const recordingPrices = [
    { price: values.price, duration: 100 },
    { price: values.price, duration: 500 },
    { price: values.price, duration: 1000 },
    { price: values.price, duration: 5000 },
    { price: values.price, duration: 10000 },
    { price: values.price, duration: 100000 }
  ];

  useEffect(() => {
    if (planConfig) {
      const price = planConfig.recordingPrice;

      setValues(values => ({
        ...values,
        price,
        amount: (price * values.duration).toFixed(2)
      }));
    }
  }, [planConfig]);

  const handleSelectDuration = item => () => {
    setValues(values => ({
      ...values,
      price: item.price,
      duration: item.duration,
      amount: (item.price * item.duration).toFixed(2)
    }));
  };

  const handleSubmit = async () => {
    const { tradeNo } = await axios.post('/api/trade/buyRecordingDuration', {
      duration: values.duration * 60
    });

    history.push(`/trade/info/${tradeNo}`);
  };

  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        variant="h5"
      >
        当前录制时间包
      </Typography>
      <PlanPackage isApiPackage />
      <Typography
        className={classes.pay}
        variant="h5"
      >
        购买录制时间包
      </Typography>
      <Card>
        <CardContent>
          <Grid
            alignItems="center"
            container
            spacing={2}
          >
            <Grid
              item
              md={2}
              xs={12}
            >
              <Typography variant="body1">选择时长：</Typography>
            </Grid>
            <Grid
              item
              md={10}
              xs={12}
            >
              {recordingPrices.map(item => (
                <Button
                  className={classes.button}
                  color={
                    values.duration === item.duration ? 'primary' : 'default'
                  }
                  disableRipple
                  key={item.duration}
                  onClick={handleSelectDuration(item)}
                  size="large"
                  variant="outlined"
                >
                  {item.duration} 分钟
                </Button>
              ))}
            </Grid>
            <Grid
              item
              md={2}
              xs={6}
            >
              <Typography variant="body1">价格：</Typography>
            </Grid>
            <Grid
              item
              md={10}
              xs={6}
            >
              <Typography
                color="secondary"
                variant="h5"
              >
                {values.price}元 / 分钟
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Grid
            container
            justify="space-between"
          >
            <Grid
              className={classes.amountWrapper}
              item
            >
              <Typography variant="body1">总金额：</Typography>
              <Typography
                className={classes.amount}
                color="primary"
                variant="body1"
              >
                {values.amount}
              </Typography>
              <Typography variant="body1">元</Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={handleSubmit}
                variant="outlined"
              >
                提交订单
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <RecordingAttention />
    </div>
  );
}

RecordingDuration.propTypes = {
  history: PropTypes.object
};

export default withRouter(RecordingDuration);
