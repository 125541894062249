import React from 'react';

// Material components
import { SvgIcon } from '@material-ui/core';

export default function Dashboard(props) {
  return (
    <SvgIcon
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M537.661312 1023.99488A481.277594 481.277594 0 0 1 54.975725 572.02914h519.805401V70.399648c255.99872 16.511917 449.213754 222.07889 449.213754 476.221619C1023.99488 811.323943 804.987975 1023.99488 537.661312 1023.99488z m-26.879866-1023.99488v508.02946H0C0 252.414738 255.422723 0 510.781446 0z" />
    </SvgIcon>
  );
}
