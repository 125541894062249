import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper, Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  paper: {
    // backgroundColor: '#f4f6f8',
    padding: theme.spacing(2)
  },
  title: {
    paddingBottom: theme.spacing(0.5)
  },
  itemName: {
    padding: theme.spacing(1),
    textAlign: 'center'
  },
  itemValue: {
    padding: theme.spacing(1),
    textAlign: 'center'
  }
}));

function round(num) {
  return Math.round(num * 100) / 100;
}

export default function PlanPackage({ isApiPackage }) {
  const classes = useStyles();

  const [freePackage, setFreePackage] = useState({});
  const [paidPackage, setPaidPackage] = useState({});

  useEffect(() => {
    (async function() {
      const [freePackage, paidPackage] = await Promise.all([
        axios.post('/api/plan/freePackage'),
        axios.post('/api/plan/paidPackage')
      ]);

      setFreePackage(freePackage);
      setPaidPackage(paidPackage);
    })();
  }, []);

  let freeDuration = isApiPackage
    ? freePackage.apiDuration
    : freePackage.recordingDuration;

  freeDuration = round(freeDuration / 60);

  let freeDurationUsed = isApiPackage
    ? freePackage.apiDurationUsed
    : freePackage.recordingDurationUsed;

  freeDurationUsed = round(freeDurationUsed / 60);

  let paidDuration = isApiPackage
    ? paidPackage.apiDuration
    : paidPackage.recordingDuration;

  paidDuration = round(paidDuration / 60);

  let paidDurationUsed = isApiPackage
    ? paidPackage.apiDurationUsed
    : paidPackage.recordingDurationUsed;

  paidDurationUsed = round(paidDurationUsed / 60);

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        justify="center"
      >
        <Grid
          container
          direction="column"
          item
          xs={6}
        >
          <Grid
            className={classes.itemName}
            item
          >
            <Typography variant="h6">赠送时间包</Typography>
          </Grid>
          <Grid
            className={classes.itemValue}
            item
          >
            <Typography
              color="primary"
              variant="h4"
            >
              {freeDurationUsed} / {freeDuration}分钟
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          item
          xs={6}
        >
          <Grid
            className={classes.itemName}
            item
          >
            <Typography variant="h6">购买时间包</Typography>
          </Grid>
          <Grid
            className={classes.itemValue}
            item
          >
            <Typography
              color="primary"
              variant="h4"
            >
              {paidDurationUsed} / {paidDuration}分钟
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

PlanPackage.propTypes = {
  isApiPackage: PropTypes.bool
};
