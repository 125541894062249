import validate from 'validate.js';

export function createFormState(values = {}) {
  return {
    isValid: false,
    values,
    touched: {},
    errors: {}
  };
}

export function hasFormError(formState, field) {
  return formState.touched[field] && formState.errors[field] ? true : false;
}

export function validateForm(values, schema) {
  const errors = validate(values, schema);

  return formState => ({
    ...formState,
    isValid: errors ? false : true,
    errors: errors || {}
  });
}

export function changeForm(formState, event) {
  event.persist();

  return formState => ({
    ...formState,
    values: {
      ...formState.values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    },
    touched: {
      ...formState.touched,
      [event.target.name]: true
    }
  });
}

export function helperText(formState, field) {
  return hasFormError(formState, field) ? formState.errors[field][0] : null
}