import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  title: {
    flex: '0 0 auto'
  },
  table: {
    minWidth: 650
  }
}));

export default function TradeList() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    total: 0
  });

  const handleChangeRowsPerPage = async event => {
    setPagination({ ...pagination, perPage: event.target.value });
  };

  const handleChangePage = (event, newPage) => {
    setPagination({ ...pagination, page: newPage });
  };

  useEffect(() => {
    (async () => {
      const { data, total } = await axios.post(
        '/api/plan/packageUsageRecords',
        {
          page: pagination.page,
          perPage: pagination.perPage
        }
      );

      const rows = data || [];

      rows.forEach(row => {
        switch (row.packageType) {
          case 'free':
            row.packageTypeDescription = '免费套餐';
            break;
          case 'paid':
            row.packageTypeDescription = '付费套餐';
            break;
          default:
            row.packageTypeDescription = row.packageType;
        }

        switch (row.tradeType) {
          case 'api_duration':
            row.tradeTypeDescription = 'sdk时间包';
            break;
          case 'recording_duration':
            row.tradeTypeDescription = '录制时间包';
            break;
          default:
            row.tradeTypeDescription = row.tradeType;
        }
      });

      setPagination(p => ({ ...p, total }));
      setRows(rows);
    })();
  }, [pagination.page, pagination.perPage]);

  return (
    <Paper className={classes.paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center">套餐类型</TableCell>
            <TableCell align="center">套餐包</TableCell>
            <TableCell align="center">消耗时间（分钟）</TableCell>
            <TableCell align="center">交易时间</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.packageTypeDescription}</TableCell>
              <TableCell align="center">{row.tradeTypeDescription}</TableCell>
              <TableCell align="center">{(row.duration / 60).toFixed(0)}</TableCell>
              <TableCell align="center">
                {moment(row.ctime).format('YYYY-MM-DD HH:mm:ss')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        backIconButtonProps={{
          'aria-label': '上一页'
        }}
        component="div"
        count={pagination.total}
        labelRowsPerPage="每页"
        nextIconButtonProps={{
          'aria-label': '下一页'
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={pagination.page}
        rowsPerPage={pagination.perPage}
        rowsPerPageOptions={[10, 20, 50]}
      />
    </Paper>
  );
}
