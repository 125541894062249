import { combineReducers, createStore } from 'redux';
import { FETCH_USER, FETCH_CONFIG, FETCH_BASE_PACKAGE } from './actions';

function user(state = {}, action) {
  switch (action.type) {
    case FETCH_USER:
      return { ...state, ...action.user };
    default:
      return state;
  }
}

function basePackage(state = {}, action) {
  switch (action.type) {
    case FETCH_BASE_PACKAGE:
      return { ...state, ...action.basePackage };
    default:
      return state;
  }
}

function config(state = {}, action) {
  switch (action.type) {
    case FETCH_CONFIG:
      return { ...state, ...action.config };
    default:
      return state;
  }
}

const reducer = combineReducers({ user, basePackage, config });

export default createStore(reducer);
