import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Typography, Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

import { BasePackage } from '../../../Package';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  title: {
    marginBottom: theme.spacing(1)
  }
}));

export default function UserPlan() {
  const classes = useStyles();
  let ops = [{ href: '/trade/plan', text: '升级套餐' }];

  return (
    <div className={classes.root}>
      <Grid
        alignItems="center"
        className={classes.title}
        container
        justify="space-between"
      >
        <Grid item>
          <Typography variant="h5">当前套餐</Typography>
        </Grid>
        <Grid item>
          {ops.map(op => (
            <Button
              className={classes.button}
              color="secondary"
              component={RouterLink}
              key={op.text}
              to={op.href}
              variant="outlined"
            >
              {op.text}
            </Button>
          ))}
        </Grid>
      </Grid>

      <BasePackage />
    </div>
  );
}
