import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import {
  createFormState,
  validateForm,
  changeForm,
  hasFormError,
  helperText
} from 'common/formState';
import Introdution from '../Introduction';

const schema = {
  name: {
    presence: { allowEmpty: false, message: '^请输入您的名字' },
    length: {
      maximum: 32
    }
  },
  mobile: {
    presence: { allowEmpty: false, message: '^请输入手机号码' }
  },
  mobileCode: {
    presence: { allowEmpty: false, message: '^请输入手机验证码' }
  },
  password: {
    presence: { allowEmpty: false, message: '^请输入密码' },
    length: {
      maximum: 128
    }
  },
  confirmPassword: {
    equality: {
      attribute: 'password',
      message: '^密码不一致'
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3),
    textAlign: 'center'
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  mobileCode: {
    justifyContent: 'space-between'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  dialogContent: {
    textAlign: 'center'
  }
}));

const SignUp = props => {
  const { history, location } = props;
  const classes = useStyles();
  const [formState, setFormState] = useState(createFormState({
    mobile: location.hash ? location.hash.substr(1) : ''
  }));
  const [countdown, setCountdown] = useState(0);
  const [captchaToken, openDialog] = useState('');
  const [captchaCode, setCaptchaCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFormState(validateForm(formState.values, schema));
  }, [formState.values]);

  const handleChange = event => {
    setFormState(changeForm(formState, event));
  };

  const handleDialogClose = () => {
    openDialog('');
  };

  const handleCaptchaCode = event => {
    event.preventDefault();

    setCaptchaCode(event.target.value);
  };

  const handleCaptchaTokenChange = async () => {
    const captcha = await axios.post('/api/public/captchaToken');
    openDialog(captcha.token);
  };

  const handleSendCode = async event => {
    event.preventDefault();

    const { mobile } = formState.values;

    if (!mobile) {
      setFormState({
        ...formState,
        touched: {
          ...formState.touched,
          mobile: true
        }
      });
      return;
    }

    if (captchaToken && !captchaCode) {
      enqueueSnackbar('请输入图形验证码', { variant: 'warning' });
      return;
    }

    try {
      await axios.post('/api/passport/sendCode', {
        username: mobile,
        templateName: '注册',
        captchaCode,
        captchaToken
      });

      openDialog('');
    } catch (error) {
      if (error.errcode === 403) {
        const captcha = await axios.post('/api/public/captchaToken');

        openDialog(captcha.token);
      }
      return;
    }

    setCountdown(60);

    const interval = setInterval(() => {
      setCountdown(preSecond => {
        if (preSecond <= 1) {
          clearInterval(interval);
          // 重置秒数
          return 0;
        } else {
          return preSecond - 1;
        }
      });
    }, 1000);
  };

  const handleSignUp = async event => {
    event.preventDefault();

    const result = await axios.post('/api/passport/signUp', formState.values);

    localStorage.setItem('name', result.name);
    history.push('/');
  };

  const hasError = field => hasFormError(formState, field);

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <Introdution />
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                autoComplete="off"
                className={classes.form}
                onSubmit={handleSignUp}
              >
                <Typography
                  className={classes.title}
                  variant="h2"
                >
                  注册
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('name')}
                  fullWidth
                  helperText={helperText(formState, 'name')}
                  label="您的名字"
                  name="name"
                  onChange={handleChange}
                  required
                  type="text"
                  value={formState.values.name || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('mobile')}
                  fullWidth
                  helperText={helperText(formState, 'mobile')}
                  label="手机号码"
                  name="mobile"
                  onChange={handleChange}
                  required
                  type="text"
                  value={formState.values.mobile || ''}
                  variant="outlined"
                />
                <Grid
                  alignItems="center"
                  className={classes.textField}
                  container
                  justify="space-between"
                >
                  <Grid
                    item
                    xs={7}
                  >
                    <TextField
                      error={hasError('mobileCode')}
                      fullWidth
                      helperText={helperText(formState, 'mobileCode')}
                      label="手机验证码"
                      name="mobileCode"
                      onChange={handleChange}
                      required
                      type="text"
                      value={formState.values.mobileCode || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      disabled={countdown > 0}
                      onClick={handleSendCode}
                      size="large"
                      type="button"
                      variant="contained"
                    >
                      {countdown > 0 ? countdown : '发送验证码'}
                    </Button>
                  </Grid>
                </Grid>
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={helperText(formState, 'password')}
                  label="密码"
                  name="password"
                  onChange={handleChange}
                  required
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  error={hasError('confirmPassword')}
                  fullWidth
                  helperText={helperText(formState, 'confirmPassword')}
                  label="确认密码"
                  name="confirmPassword"
                  onChange={handleChange}
                  required
                  type="password"
                  value={formState.values.confirmPassword || ''}
                  variant="outlined"
                />
                <Button
                  className={classes.signUpButton}
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  注册
                </Button>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  已经有账号？{' '}
                  <Link
                    component={RouterLink}
                    to="/passport/sign-in"
                    variant="h6"
                  >
                    登录
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
          <Dialog
            aria-labelledby="form-dialog-title"
            onClose={handleDialogClose}
            open={Boolean(captchaToken)}
          >
            <DialogTitle id="form-dialog-title">人机验证</DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <img
                alt="图形验证码"
                onClick={handleCaptchaTokenChange}
                src={
                  captchaToken
                    ? `/api/public/captcha/${captchaToken}.png`
                    : null
                }
              />
              <TextField
                autoFocus
                fullWidth
                id="name"
                label="图形验证码"
                margin="dense"
                onChange={handleCaptchaCode}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={handleSendCode}
              >
                提交
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(SignUp);
