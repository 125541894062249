import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

import { ConfereceRunning, PackageSummary, ApiKey } from './components';
import { RoomList } from '../Room/RoomList';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1)
    }
  },
  roomSection: {
    marginTop: theme.spacing(3)
  },
  roomTitle: {
    marginBottom: theme.spacing(2)
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ConfereceRunning />
      <div className={classes.roomSection}>
        <Typography
          className={classes.roomTitle}
          variant="h4"
        >
          会议室列表
        </Typography>
        <RoomList hideTitle />
      </div>
      <PackageSummary />
      <ApiKey />
    </div>
  );
};

export default Dashboard;
