import React from 'react';

// Material components
import { SvgIcon } from '@material-ui/core';

export default function Recording(props) {
  return (
    <SvgIcon
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M746.656 310.528l110.688-49.184a42.656 42.656 0 0 1 60 38.976v423.36a42.656 42.656 0 0 1-60 38.976l-110.688-49.184V768a85.344 85.344 0 0 1-85.312 85.344H192A85.344 85.344 0 0 1 106.656 768V256A85.344 85.344 0 0 1 192 170.656h469.344A85.344 85.344 0 0 1 746.656 256v54.528zM320 381.184v261.632a21.344 21.344 0 0 0 33.152 17.728l196.224-130.784a21.344 21.344 0 0 0 0-35.52l-196.224-130.784a21.344 21.344 0 0 0-33.152 17.728z" />
    </SvgIcon>
  );
}
