import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useSnackbar } from 'notistack';
import { FETCH_USER } from 'actions';
import axios from 'axios';

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = props => {
  const { className, ...rest } = props;
  const user = useSelector(state => state.user, shallowEqual);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleChange = event => {
    dispatch({
      type: FETCH_USER,
      user: {
        ...user,
        [event.target.name]: event.target.value.trim()
      }
    });
  };
  const handleSave = async event => {
    event.preventDefault();
    
    if (!user.name) {
      enqueueSnackbar('姓名不能为空', {
        variant: 'error'
      });
      return;
    }
    await axios.post('/api/user/updateInfo', user);

    enqueueSnackbar('个人信息已保存', {
      variant: 'success'
    });
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="可编辑"
          title="个人资料"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="姓名"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                value={user.name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="可用于找回密码"
                label="邮箱"
                margin="dense"
                name="email"
                onChange={handleChange}
                value={user.email || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="公司名称"
                margin="dense"
                name="companyName"
                onChange={handleChange}
                value={user.companyName || ''}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="公司网站"
                margin="dense"
                name="companySite"
                onChange={handleChange}
                value={user.companySite || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            onClick={handleSave}
            variant="outlined"
          >
            保存
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
